import {EventType, customEventTypes} from '@wix/editor-platform-sdk-types'

import {
  initMainFrame,
  initSender,
  initReceiver,
} from './js/api/events/propagation'
import {eventsFactory as factory} from './js/api/events/factory'

export {EventType, factory, customEventTypes}
export const propagation = {initMainFrame, initSender, initReceiver}
