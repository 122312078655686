import cloneDeep from 'lodash/cloneDeep'

import CustomEvent from '../../custom-event-polyfill'

import {AppData, EditorSDK, PlatformEvent} from '@wix/editor-platform-sdk-types'

// to limit confusion,
// `PlatformEvent`s are `event`s,
// while browser `MessageEvent`s are `msg`s

// Edge 18 doesn't have `globalThis`
const _globalThis: typeof globalThis =
  typeof globalThis === 'undefined' ? self : globalThis

const connectReceiverWithSenderMsg =
  'event propagation: connect receiver with sender'
const connectSenderWithMainFrameMsg =
  'event propagation: connect sender with main frame'
const eventFromSenderMsg = 'event propagation: event from sender'
const senderReadyMsg = 'event propagation: sender ready'
const receiverReadyMsg = 'event propagation: receiver ready'
const receiverCloseMsg = 'event propagation: receiver close'

type PropagationMessageData<T extends string> = {
  type: T
} & (T extends typeof eventFromSenderMsg
  ? {
      eventType: PlatformEvent['eventType']
      eventPayload: PlatformEvent['eventPayload']
    }
  : T extends typeof receiverReadyMsg
    ? {appDefinitionId?: string}
    : T extends typeof connectReceiverWithSenderMsg
      ? {appDefinitionId?: string}
      : unknown)

type PropagationMessageEvent<T extends string> = MessageEvent<
  PropagationMessageData<T>
>

function sourceCanReceiveMessages(
  msg: MessageEvent,
): msg is MessageEvent & {source: Window} {
  return typeof msg.source?.postMessage === 'function'
}

function isAllowedOrigin(origin: string) {
  const url = new URL(origin)

  if (_globalThis.location?.hostname === url.hostname) {
    // special case for tests,
    // where both main and receiver frames are loaded from, for example, localhost
    return true
  }

  return (
    url.hostname === 'static.parastorage.com' ||
    url.hostname.endsWith('.wix.com') ||
    url.hostname.endsWith('.editorx.com') ||
    url.hostname.endsWith('.wix.dev')
  )
}

function isAllowedGlobalMessage(
  msg: MessageEvent,
): msg is MessageEvent & {source: Window} {
  if (!sourceCanReceiveMessages(msg)) {
    return false
  }

  return isAllowedOrigin(msg.origin)
}

/*
main frame has two roles:
- when sender frame is ready, establish connection (via `senderChannel`) between main and sender frames.
  this connection will be later used in `handleReceiverReady` to…
- when receiver frame is ready, establish connection (via `receiverChannel`) between sender and receiver frames
*/
export function initMainFrame() {
  const senderChannel = new MessageChannel()
  const ownSenderPort = senderChannel.port1
  const transferredSenderPort = senderChannel.port2

  function handleSenderReady(
    msg: PropagationMessageEvent<typeof senderReadyMsg>,
  ) {
    if (msg.data?.type !== senderReadyMsg) {
      return
    }

    if (!isAllowedGlobalMessage(msg)) {
      return
    }

    _globalThis.removeEventListener('message', handleSenderReady)

    const connectMsg: PropagationMessageData<
      typeof connectSenderWithMainFrameMsg
    > = {type: connectSenderWithMainFrameMsg}

    msg.source.postMessage(connectMsg, msg.origin, [transferredSenderPort])
  }

  function handleReceiverReady(
    msg: PropagationMessageEvent<typeof receiverReadyMsg>,
  ) {
    if (msg.data?.type !== receiverReadyMsg) {
      return
    }

    // platform events don't leak sensitive info, so there's no need to check receiver's `msg.origin`
    // if (!isAllowedGlobalMessage(msg)) {
    if (!sourceCanReceiveMessages(msg)) {
      return
    }

    // both ports are transferred out of main frame
    const receiverChannel = new MessageChannel()

    const connectMsg: PropagationMessageData<
      typeof connectReceiverWithSenderMsg
    > = {
      type: connectReceiverWithSenderMsg,
      appDefinitionId: msg.data.appDefinitionId,
    }

    ownSenderPort.postMessage(connectMsg, [receiverChannel.port1])

    msg.source.postMessage(connectMsg, msg.origin, [receiverChannel.port2])
  }

  _globalThis.addEventListener('message', handleSenderReady)
  _globalThis.addEventListener('message', handleReceiverReady)
}

let _structureCloneCheckerPort
function checkIfValueCanBeStructuredCloned(value) {
  /*
    There's (as of June 2021) no "nice" API to check whether value can be passed through `MessagePort`,
    (i.e. can be "structure cloned"), so we do it by shoving that value into no-op `_structureCloneCheckerPort` port

    > Why do we need to check it in a first place?

    Because something might try to propagate an event with a `Proxy` object in its payload,
    and those can't be `postMessage`d and can't be reliably detected (there's no (recurvise) `Proxy.isProxy`)

    see also:
    - https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
    - https://dassur.ma/things/deep-copy/
    - https://wix.slack.com/archives/CQGJP31CM/p1623225837185800
  */

  if (value === null || value === undefined) {
    return true
  }

  if (!_structureCloneCheckerPort) {
    const {port1} = new MessageChannel()
    _structureCloneCheckerPort = port1
  }

  try {
    _structureCloneCheckerPort.postMessage(value)
  } catch (e) {
    if (e?.name === 'DataCloneError') {
      return false
    }

    throw e
  }

  return true
}

export function initSender() {
  let mainFramePort: MessagePort | null = null
  const receiverPorts: Set<MessagePort> = new Set()
  const receiverPortsMetadata: WeakMap<MessagePort, {appDefinitionId: string}> =
    new WeakMap()

  function handleConnectReceiverWithSender(
    msg: PropagationMessageEvent<typeof connectReceiverWithSenderMsg>,
  ) {
    if (msg.data?.type !== connectReceiverWithSenderMsg) {
      return
    }

    const receiverPort = msg.ports[0]
    receiverPorts.add(receiverPort)
    receiverPortsMetadata.set(receiverPort, {
      appDefinitionId: msg.data.appDefinitionId,
    })

    function handleReceiverClose(
      msg: PropagationMessageEvent<typeof receiverCloseMsg>,
    ) {
      if (msg.data?.type !== receiverCloseMsg) {
        return
      }

      receiverPort.removeEventListener('message', handleReceiverClose)
      receiverPorts.delete(receiverPort)
    }

    receiverPort.addEventListener('message', handleReceiverClose)
    receiverPort.start()
  }

  type PropagateEventOptions = {
    onlyTo?: {appDefinitionId?: string}
  }

  // in case there're events after main frame is ready,
  // but before sender calls `repeatSenderReadyMsgUntilConnected`
  const propagationQueue: {
    eventType: PlatformEvent['eventType']
    eventPayload: PlatformEvent['eventPayload']
    options?: PropagateEventOptions
  }[] = []

  function handleHandshakeMainSender(
    msg: PropagationMessageEvent<typeof connectSenderWithMainFrameMsg>,
  ) {
    if (msg.data?.type !== connectSenderWithMainFrameMsg) {
      return
    }

    if (!isAllowedGlobalMessage(msg)) {
      return
    }

    _globalThis.removeEventListener('message', handleHandshakeMainSender)

    mainFramePort = msg.ports[0]
    mainFramePort.addEventListener('message', handleConnectReceiverWithSender)
    mainFramePort.start()

    while (propagationQueue.length) {
      const {eventType, eventPayload, options} = propagationQueue.shift()
      propagateEvent(eventType, eventPayload, options)
    }
  }

  function propagateEvent(
    eventType: PlatformEvent['eventType'],
    eventPayload: PlatformEvent['eventPayload'],
    options?: PropagateEventOptions,
  ) {
    if (!mainFramePort) {
      propagationQueue.push({eventType, eventPayload, options})
      return
    }

    const eventMsg: PropagationMessageData<typeof eventFromSenderMsg> = {
      type: eventFromSenderMsg,
      eventType,
      eventPayload: checkIfValueCanBeStructuredCloned(eventPayload)
        ? eventPayload
        : cloneDeep(eventPayload),
    }

    function getTargetPorts() {
      return Array.from(receiverPorts).filter(
        (port) =>
          receiverPortsMetadata.get(port)?.appDefinitionId ===
          options?.onlyTo?.appDefinitionId,
      ) as MessagePort[]
    }

    const targetPorts = options?.onlyTo?.appDefinitionId
      ? getTargetPorts()
      : receiverPorts

    for (const port of targetPorts) {
      port.postMessage(eventMsg)
    }
  }

  _globalThis.addEventListener('message', handleHandshakeMainSender)

  function repeatSenderReadyMsgUntilConnected() {
    // we don't control the order in which `initSender()` and `initMainFrame()`
    // are executed (they are called in different frames), so sender repeats
    // `senderReadyMsg` until main frame answers with a `mainFramePort`
    if (mainFramePort) {
      return
    }

    _globalThis.parent.postMessage(
      {type: senderReadyMsg},
      '*', // it's still okay if parent frame isn't controlled by us because this message doesn't expose anything of value
    )

    setTimeout(repeatSenderReadyMsgUntilConnected, 30)
  }

  repeatSenderReadyMsgUntilConnected()

  return {
    propagateEvent,
  }
}

export function initReceiver(
  editorSDK: EditorSDK,
  appData: Pick<AppData, 'appDefinitionId'>,
) {
  let senderPort: MessagePort | null = null

  function handleEventFromSender(
    msg: PropagationMessageEvent<typeof eventFromSenderMsg>,
  ) {
    if (msg.data?.type !== eventFromSenderMsg) {
      return
    }

    const {eventType, eventPayload} = msg.data

    editorSDK.__dispatchEvent(
      new CustomEvent(eventType, {detail: eventPayload}),
    )
  }

  function handleConnectReceiverWithSender(
    msg: PropagationMessageEvent<typeof connectReceiverWithSenderMsg>,
  ) {
    if (msg.data?.type !== connectReceiverWithSenderMsg) {
      return
    }

    if (!isAllowedGlobalMessage(msg)) {
      return
    }

    _globalThis.removeEventListener('message', handleConnectReceiverWithSender)
    _globalThis.addEventListener('beforeunload', () => {
      const message: PropagationMessageData<typeof receiverCloseMsg> = {
        type: receiverCloseMsg,
      }
      senderPort?.postMessage(message)
    })

    senderPort = msg.ports[0]
    senderPort.addEventListener('message', handleEventFromSender)
    senderPort.start()
  }

  _globalThis.addEventListener('message', handleConnectReceiverWithSender)
  const message: PropagationMessageData<typeof receiverReadyMsg> = {
    type: receiverReadyMsg,
    appDefinitionId: appData.appDefinitionId,
  }
  _globalThis.parent.postMessage(
    message,
    '*', // it's still okay if parent frame isn't controlled by us because this message doesn't expose anything of value
  )
}
