// based on https://github.com/ungap/custom-event
/** @type {{CustomEvent: typeof CustomEvent}} */
const self = {} // eslint-disable-line consistent-this

if (typeof CustomEvent === 'function') {
  self.CustomEvent = CustomEvent
} else if (typeof Event === 'function') {
  // Safari Web Worker case
  self.CustomEvent = class CustomEvent extends Event {
    constructor(type, eventInit) {
      super(type, eventInit)
      this.detail = (eventInit && eventInit.detail) || null
    }
  }
} else if (typeof document === 'undefined') {
  // node tests
  self.CustomEvent = class CustomEvent {
    constructor(type, eventInit) {
      if (typeof type === 'undefined') {
        throw new TypeError('Not enough arguments')
      }

      this.type = type
      this.bubbles = (eventInit && !!eventInit.bubbles) || false
      this.cancelable = (eventInit && !!eventInit.cancelable) || false
      this.composed = (eventInit && !!eventInit.composed) || false
      this.detail = (eventInit && eventInit.detail) || null
    }
  }
} else {
  // the IE11 case
  /*! (c) Andrea Giammarchi - ISC */
  self.CustomEvent = (function (__p__) {
    CustomEvent[__p__] = new CustomEvent('').constructor[__p__]
    return CustomEvent
    function CustomEvent(type, init) {
      if (!init) {
        init = {}
      }
      const e = document.createEvent('CustomEvent')
      e.initCustomEvent(type, !!init.bubbles, !!init.cancelable, init.detail)
      return e
    }
  })('prototype')
}

export default self.CustomEvent
